<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
        <banner
          title="INVOICE"
          :breadcrumb="[
            {
              label: 'Professional',
            },
            { label: 'Invoice' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="content-body" style="margin-top: -50px">
          <!-- users list start -->
          <section>
            <div class="row" style="margin-top: -20px">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div
                  class="card custom-card"
                
                >
                  <div class="row" style="padding: 10px 10px 5px 10px">
                    <div class="col-10">
                      <h3 class="text"><b>Invoices</b></h3>
                    </div>
                    <div class="col-2 text-end">
                      <button
                        class="btn btn-sm text-white"
                        style="background-color: #f21000"
                      >
                        + New
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-12 col-xl-12 col-sm-12"
                      style="padding: 0% 1% 0% 1%"
                    >
                      <div class="container-fluid table-scroll">
                        <table class="table table-hover table-sm">
                          <tr
                            class="text tr-head table-wrapper-scroll-y rounded-circle"
                            style="background-color: white"
                          >
                            <th
                              class="text-truncate"
                              style="
                                background-color: #cdcdcd;
                                border-radius: 8px 0px 0px 8px;
                              "
                            >
                              ID
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Date
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Invoice No.
                            </th>

                            <th style="background-color: #cdcdcd" class="text-truncate">
                              Billing Month
                            </th>
                            <th style="background-color: #cdcdcd" class="text-truncate">
                              Invoice Type
                            </th>
                            <th
                              style="background-color: #cdcdcd"
                              class="text-truncate text-center"
                            >
                              Amount
                            </th>

                            <th
                              style="background-color: #cdcdcd"
                              class="text-truncate text-center"
                            >
                              Status
                            </th>
                            <th
                              style="
                                background-color: #cdcdcd;
                                border-radius: 0px 8px 8px 0px;
                              "
                              class="text-truncate text-end"
                            >
                              Action
                            </th>
                          </tr>

                          <tbody class="text">
                            <tr v-for="(Invoice, index) in this.clients" :key="index">
                              <td>{{ Invoice.clientServiceNo }}</td>
                              <td>{{ Invoice.date }}</td>
                              <td>{{ Invoice.date }}</td>
                              <td>{{ Invoice.date }}</td>
                              <td>{{ Invoice.date }}</td>
                              <td>{{ Invoice.serviceCharge }}</td>
                              <td>{{ Invoice.date }}</td>
                              <td>{{ Invoice.date }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div class="col-8">
                        <ul class="pagination">
                          <li class="page-item" @click="loadClients(null, 'first')">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                            </a>
                          </li>
                          <li class="page-item" @click="loadClients(null, 'prev')">
                            <a class="page-link" href="#">Previous</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page > 3"
                            @click="loadClients(pagination.current_page - 3)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page - 3
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page > 2"
                            @click="loadClients(pagination.current_page - 2)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page - 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page > 1"
                            @click="loadClients(pagination.current_page - 1)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page - 1
                            }}</a>
                          </li>
                          <li
                            class="active page-item"
                            @click="loadClients(pagination.current_page)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 4"
                            @click="loadClients(pagination.current_page + 1)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page + 1
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 5"
                            @click="loadClients(pagination.current_page + 2)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page + 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 6"
                            @click="loadClients(pagination.current_page + 3)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page + 3
                            }}</a>
                          </li>
                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                          <li class="page-item">
                            <a class="page-link" href="#">...</a>
                          </li>

                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 3"
                            @click="loadClients(pagination.last_page - 3)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.last_page - 3
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 2"
                            @click="loadClients(pagination.last_page - 2)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.last_page - 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 1"
                            @click="loadClients(pagination.last_page - 1)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.last_page - 1
                            }}</a>
                          </li>

                          <li class="page-item" @click="loadClients(null, 'next')">
                            <a class="page-link" href="#">Next</a>
                          </li>
                          <li class="page-item" @click="loadClients(null, 'last')">
                            <a class="page-link" href="#">&raquo;</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-4 text-end">
                        Showing {{ pagination.from }} to {{ pagination.to }} of
                        {{ pagination.total }} entries
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- users list ends -->
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- model  -->
  <!-- Button trigger modal -->
</template>

<script>
import moment from "moment";
import Spinner from "../../../components/professional/comman/Spinner.vue";
import Banner from "../../../components/professional/comman/Banner.vue";
// import Form from 'vform'
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "ProfessionalInvoces",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 5,
      loading: false,
      services: [],
      clients: [],
    };
  },
  methods: {
    statusNoData(status) {
      // allrecords;
      let num = 0;
      for (var i = 0; i < this.clients.length; i++) {
        if (this.clients[i].status == status) {
          num++;
        }
        // if (this.clients[i].status == "cancelled") {
        //   num++;
        // }
      }
      return num;
    },
    cancelProject(id) {
      this.$swal
        .fire({
          title: "Are you sure For Cancel ?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .post(
                `retailer/cancelclientservice`,
                { client_service_id: id },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.accessToken,
                  },
                }
              )
              .then((res) => {
                res.data;
                // console.log(res.data.data)
                this.$swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "The Project Cancelled successfully.",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.loadClients();
              })
              .finally(() => (this.loading = false));
          }
        });
    },

    loadClients(page = null, pg = null) {
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        pageUrl += `professional/clientservice?per_page=${this.per_page}`;
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.clients = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    dateTime(value) {
      console.log(value);
      return moment(value).format("DD-MM-YYYY");
    },
    idStore(id) {
      var Invoice = {};
      // itr = JSON.parse(localStorage.getItem('in'))
      Invoice.id = id;
      localStorage.setItem("Invoice", JSON.stringify(Invoice));
    },
    loadPageTitle(){
        document.title =this.$store.state.professionalPageTitles.Invoice
      }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadClients();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -20px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
</style>
